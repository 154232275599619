import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import InvenChargesVector from "../../assets/images/inven-charges-vector.svg";
import InventoryImg from "../../assets/images/imgg.png";
import Warehouse from "../../assets/images/workhouse-to-warehouse.svg";
import InventoryCount from "../../assets/images/inventory-count.svg";
import "./inventoryDashboard.css";
import InventoryService from "../../shared/_services/inventory.service";
import Constant from "../../shared/_helpers/constants";
import UserContext from "../../context/userContext";

const InventoryDashboard = ({ warehouseItemsData }) => {
  const [inventoryChargeLength, setInventoryChargesCounts] = useState(0);
  const [warehouseMovesLength, setWarehouseMovesLength] = useState(0);
  const [inventoryCountLength, setInventoryCountLength] = useState(0);

  const account = useContext(UserContext);
  const Email = account?.email;
  const CanoID = account?.CanoID;
  const CompanyCode = account?.DefaultCompany;
  const LoginUserType = account.UserType;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ICResponse, WMReponse, ICTReponse] = await Promise.all([
          axios.get(
            `${Constant.BASE_URL}/api/APIv1GetJobRequisitionHistory?CanoID=${CanoID}`
          ),
          axios.get(
            `${Constant.BASE_URL}/api/APIv1GetWarehouseToWarehouseTransferHistory?CanoID=${CanoID}`
          ),
          axios.get(
            `${Constant.BASE_URL}/api/APIv1GetInventoryAdjustmentHistory?CanoID=${CanoID}`
          ),
        ]);

        setInventoryChargesCounts(
          Object.keys(InventoryService.groupByData(ICResponse.data, "DTS"))
            .length || 0
        );
        setWarehouseMovesLength(
          Object.keys(InventoryService.groupByData(WMReponse.data, "DTS"))
            .length || 0
        );
        setInventoryCountLength(ICTReponse.data.length || 0);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [CanoID, CompanyCode, Email, warehouseItemsData?.data?.data?.length]);

  return (
    <div className="home_content">
      <div className="container-fluid">
        <div className="row">
          <div 
          // className="col-lg-6" 
          className={`col-lg-6 ${LoginUserType === "Field" ? "field-class" : ""}`}
          >
            <Link to="/inventory-charges">
              <div className="Inventory-Charges ">
                <h3 style={{ color: "#000" }}>{inventoryChargeLength}</h3>
                <p>Warehouse to Job </p>
                <div className="img-footer">
                 {LoginUserType !== "Field" && (<img src={InvenChargesVector} className="img-fluid" alt="vector" draggable="false" />)}
                </div>
              </div>
            </Link>
          </div>
          {LoginUserType !== "Field" && (
            <>
              <div className="col-lg-6">
                <Link to="/warehouse">
                  <div className="Inventory-Charges ">
                    <h3 style={{ color: "#000" }}>{warehouseMovesLength}</h3>
                    <p>Warehouse to Warehouse Move</p>
                    <div className="img-footer">
                      <img
                        src={Warehouse}
                        className="img-fluid"
                        alt="warehouse img"
                        draggable="false"
                      />
                    </div>
                  </div>
                </Link>
              </div>
            </>)}
        </div>
        {LoginUserType !== "Field" && (
          <>
            <div className="row mt-3">
              <div className="col-lg-12 ">
                <Link to="/inventory-counts">
                  <div className="Inventory-count ">
                    <div className="row ">
                      <div className="col-lg-6 d-flex align-items-center justify-content-center">
                        <div className="">
                          <h3 style={{ color: "#000" }}>{inventoryCountLength}</h3>
                          <p>Inventory Counts</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="in-count-vec">
                          <div className="img-footer2">
                            <img
                              src={InventoryCount}
                              className="img-fluid"
                              alt="inventory count"
                              draggable="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </>)}
          {LoginUserType === "Field" && (<img src={InventoryImg} className="img-fluid field-img" alt="vector" draggable="false"/>)}
      </div>
    </div>
  );
};

export default InventoryDashboard;
