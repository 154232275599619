import React from "react";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { Container, Row, Col } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
// import moment from "moment";
import axios from "axios";
import Login_bg from "../../assets/images/login-bg.jpg";
import scan_vec from "../../assets/images/scan_vec.png";
import Logo from "../../assets/images/logo.png";

import "./Login.css";

const Login = () => {
  const { instance } = useMsal();

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    // Month is 0-indexed in JavaScript Date, so subtract 1 from the month
    return new Date(year, month - 1, day);
  };

  const checkCompanyPaymentStatus = (c_payment) => {
    const { JoiningDate, TrialDays, StripePaymentCharged } = c_payment?.data; 
    const currentDate = new Date();
  
    if (JoiningDate && TrialDays) {
      const joiningDate = new Date(JoiningDate);
      const trialEndDate = new Date(joiningDate);
      trialEndDate.setDate(trialEndDate.getDate() + TrialDays);
  
      if (currentDate <= trialEndDate) {
        return true; 
      }
    }
  
    if (StripePaymentCharged === true) {
      return true; 
    }
  
    return false;
  };
  

  const checkEmployeePaymentStatus = (e_payment) => {
    console.log("e_payment", e_payment);

    if (parseDate(e_payment.StripeSubscriptionID) > new Date()) {
      return true;
    } else {
      return false;
    }
  };

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        const Email = res.account.username.toLowerCase();
        // const Email = "yen@cct123.com";
        axios
          .get(
            `https://celtic.azurewebsites.net/api/APIv1GetUserFromEmail?Email=${Email.replaceAll(
              "+",
              "%2B"
            )}`
          )
          .then(({ data }) => {
            if (
              (data.UserType === "Tech" || data.UserType === "Hybrid User" || data.UserType === "Field") &&
              data.Disabled === "N"
            ) {
              axios
                .get(
                  `https://celtic.azurewebsites.net/api/APIv1GetCanoAccount?CanoID=${data.CanoID}`
                )
                .then((companyData) => {
                  if (companyData.data.Disabled === false) {
                    const companyPaymentStatus =
                      checkCompanyPaymentStatus(companyData);
                    const employeePamentStatus =
                      checkEmployeePaymentStatus(data);
                    console.log({ companyPaymentStatus, employeePamentStatus });

                    if (companyPaymentStatus || employeePamentStatus) {
                      data["Payment"] = true;

                      data["auth"] = "msoft";

                      localStorage.setItem(
                        "CanoUserDetails",
                        JSON.stringify(data)
                      );
                      window.location.href = "/";
                    } else {
                      alert(
                        "Your trial has been expired or subscription has been expired."
                      );
                      return instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                      });
                    }
                  } else {
                    alert("Your company is not authorized to access this");
                    return instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  alert("You are not authorized to access this");
                  return instance.logoutRedirect({
                    postLogoutRedirectUri: "/",
                  });
                });
            } else {
              alert("You are not authorized to access this");
              return instance.logoutRedirect({ postLogoutRedirectUri: "/" });
            }
          })
          .catch((err) => {
            console.log(err);
            return instance.logoutRedirect({ postLogoutRedirectUri: "/" });
          });
      })
      .catch((e) => {
        console.log(e);
        return instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      });
  };

  const extractEmailFromGoogleResponse = (response) => {
    // Decode the JWT token to get user information
    const tokenParts = response.credential.split(".");
    const payload = JSON.parse(atob(tokenParts[1]));
    const email = payload.email;

    return email;
  };
  const handleGoogleLogin = (response) => {
    const Email = extractEmailFromGoogleResponse(response);
    // const Email = "canoconsulting@homelectrical.com";
    console.log(Email);
    axios
      .get(
        `https://celtic.azurewebsites.net/api/APIv1GetUserFromEmail?Email=${Email.replaceAll(
          "+",
          "%2B"
        )}`
      )
      .then(({ data }) => {
        if (
          (data.UserType === "Tech" || data.UserType === "Hybrid User" || data.UserType === "Field") &&
          data.Disabled === "N"
        ) {
          axios
            .get(
              `https://celtic.azurewebsites.net/api/APIv1GetCanoAccount?CanoID=${data.CanoID}`
            )
            .then((companyData) => {
              if (companyData.data.Disabled === false) {
                const companyPaymentStatus =
                  checkCompanyPaymentStatus(companyData);
                const employeePamentStatus = checkEmployeePaymentStatus(data);

                if (companyPaymentStatus || employeePamentStatus) {
                  data["Payment"] = true;

                  data["auth"] = "google";

                  localStorage.setItem("CanoUserDetails", JSON.stringify(data));

                  window.location.href = "/";
                } else {
                  alert(
                    "Your trial has been expired or subscription has been expired."
                  );
                  googleLogout();
                }
              } else {
                alert("Your company is not authorized to access this");
                googleLogout();
              }
            })
            .catch((error) => {
              console.log(error);
              alert("You are not authorized to access this");
              googleLogout();
            });
        } else {
          alert("You are not authorized to access this");
          googleLogout();
        }
      })
      .catch((err) => {
        console.log(err);
        googleLogout();
      });
  };

  return (
    <div
      className="login"
      id="login"
      style={{ backgroundImage: `url(${Login_bg})` }}
    >
      <Container>
        <Row>
          <Col lg={5}></Col>
          <Col lg={7}>
            <div className="content-mid">
              <div className="mng-login-des">
                <div className="login-bg">
                  <div className="login-logo text-center">
                    <img
                      src={Logo}
                      className="img-fluid"
                      alt="CANO LOGO"
                      width="200px"
                    />
                  </div>
                  <div className="form-style">
                    <div className="text-center py-4">
                      <img
                        className="align-center "
                        src={scan_vec}
                        alt="scanner"
                        width="120px"
                      />
                    </div>
                    <h2 className="heading text-center">
                      Welcome to Cano Consulting
                    </h2>
                    <p className="text-center">
                      Sign in now and dive into the world of seamless scanning.
                    </p>
                    <div className="text-center pt-2">
                      <button
                        onClick={() => handleLogin()}
                        type="button"
                        className="primary-btn"
                      >
                        Sign in with Microsoft
                      </button>
                      <div className="google-login-container">
                        <GoogleLogin
                          clientId="973471171711-2inbv52i7cp9vi7uq9rndoosvo3l4k0n.apps.googleusercontent.com"
                          onSuccess={(response) => handleGoogleLogin(response)}
                          onFailure={() => console.log("Google login failed")}
                          theme="filled_blue"
                          shape="circle"
                          size="large"
                          width="255px"
                          render={(renderProps) => (
                            <button
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                              type="button"
                              className="primary-btn"
                            >
                              Sign in with Google
                            </button>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
