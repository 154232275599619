import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import axios from "axios";
import moment from "moment";

import Constant from "../../shared/_helpers/constants";
import SearchIcon from "../../assets/images/Search.svg";
import DataTable from "react-data-table-component";
import AddIcon from "../../assets/images/add-white.svg";
import PersonDollyIcon from "../../assets/images/job-img-table.svg";
import UserContext from "../../context/userContext";

const InventoryCharges = ({ warehouseItemsData }) => {
  const navigate = useNavigate();
  const account = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const CanoID = account.CanoID;
  const CompanyCode = account.DefaultCompany;
  const Email = account.email;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [JobReqAPI, JobNameAPI] = await Promise.all([
          axios.get(
            `${Constant.BASE_URL}/api/APIv1GetJobRequisitionHistory?CanoID=${CanoID}`
          ),
          axios.get(
            `${Constant.BASE_URL}/api/APIv1GetJob?CanoID=${CanoID}&CompanyCode=${CompanyCode}`
          ),
        ]);
  
        if (JobReqAPI?.data?.failure !== "API returned an empty set") {
          const JobList = JobNameAPI?.data?.data.reduce((acc, item) => {
            acc[item?.Job_Number?.trim()] = item?.Job_Description?.trim();
            return acc;
          }, {});
          const ItemsList = warehouseItemsData?.data?.data.reduce((acc, item) => {
            acc[item?.Item_Code?.trim()] = item?.Item_Description?.trim();
            return acc;
          }, {});
  
          const groupedData = JobReqAPI?.data?.reduce((result, item) => {
            const JobKey = moment(item["DTS"], "M/D/YYYY h:mm A");
          
            // Check if JobNumber exists in JobList
            if (JobList[item["JobNumber"]?.trim()]) {
              if (!result[JobKey]) {
                result[JobKey] = {
                  JobNumber: item["JobNumber"],
                  JobName: JobList[item["JobNumber"]?.trim()], // Only matched JobNumber is added
                  PhaseName: item["PhaseName"],
                  CompanyCode: item["CompanyCode"],
                  ChargeBy: item["Email"],
                  Transaction_Date: moment
                    .unix(item["BatchCode"] / 1000)
                    .format("YYYY-MM-DD HH:mm"),
                  details: [],
                };
              }
              item["ItemName"] = ItemsList[item?.ItemCode?.trim()];
              result[JobKey]?.details?.push(item); 
            }
          
            return result;
          }, {});
          
          const groupedArray = Object.values(groupedData);
          setUsers(groupedArray);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [CanoID, CompanyCode, Email, warehouseItemsData?.data?.data]);
  
  

  const handleRowClick = (row) => {
    navigate("/inventory-charges/inventory-charge-item-details", {
      state: { data: row },
    });
  };

  useEffect(() => {
    const pattern = /^[a-zA-Z0-9/ -]*$/; // Allow space in the pattern
    if (search.match(pattern) === null) {
      return setFilteredData([]);
    }

    const searchWords = search.toLowerCase().split(" "); // Split the search string into words
    const resultData = users.filter((user) => {
      return searchWords.every((word) =>
        user?.JobName?.toLowerCase().includes(word)
      );
    });

    setFilteredData(resultData);
  }, [search, users]);


  const columns = [
    {
      name: "Job",
      minWidth: "300px",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <img src={PersonDollyIcon} alt="Profile" />
          {console.log("row",row?.JobName)}
          <span className="mx-2">{row?.JobName?.replace("&amp;", "&")}</span>
        </div>
      ),
    },
    {
      name: "Company Code",
      minWidth: "200px",
      selector: (row) => row?.CompanyCode,
      sortable: true,
    },
    {
      name: "Phase",
      minWidth: "250px",
      selector: (row) => row?.PhaseName.replace("&amp;", "&"),
      sortable: true,
    },
    {
      name: "Date",
      minWidth: "200px",
      selector: (row) => row?.Transaction_Date,
      sortable: true,
    },
    {
      name: "Items",
      selector: (row) => row?.details.length,
      minWidth: "100px",
      sortable: true,
    },
    {
      name: "Charged By",
      minWidth: "300px",
      selector: (row) => row?.ChargeBy,
      sortable: true,
    },
  ];

  return (
    <div className="container-fluid align-left inventory-count inventory">
      <div className="page-heading">
        <h4 className="align-left top_head">Warehouse to Job</h4>
      </div>
      <DataTable
        columns={columns}
        data={search ? filteredData : users}
        pagination
        onRowClicked={(row) => handleRowClick(row)}
        subHeader
        subHeaderComponent={
          <div className="d-flex justify-content-between w-100 search_head">
            <div className="input-group w-50">
              <img src={SearchIcon} className="search-icon" alt="search" />
              <input
                type="text"
                placeholder="Search by job name"
                className="form-control w-25"
                style={{
                  borderRadius: "40px",
                  paddingLeft: "40px",
                  height: "45px",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div>
              <div className="btn-custom scan-item">
                <Link to="/inventory-charges/add-inventory-charge">
                  <button>
                    <img src={AddIcon} alt="add Icon" /> Add New
                  </button>
                </Link>
              </div>
            </div>
          </div>
        }
        subHeaderAlign="left"
        progressPending={loading}
        progressComponent={
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              role="status"
              style={{ color: "skyblue" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      />
    </div>
  );
};

export default InventoryCharges;
